interface Response {
  access_token: string;
  user?: object;
}
export const auth = {
  setToken(data: Response) {
    localStorage.setItem("token", data.access_token);
  },
  active() {
    return localStorage.getItem("token") || false;
  },
  logout() {
    localStorage.removeItem("token");
    location.replace("/");
  },
};
