import { ref } from "vue";
import { User } from "@/models/user";

type Branch = { id: number; name: string };
const branch = ref<Branch>();
const user = ref<User | null>(null);
export function useAuth() {
  const setCurrentUser = (row: User) => {
    user.value = row;
  };
  return { user, setCurrentUser, branch };
}
