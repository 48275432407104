import { ref } from "vue";
import axios from "axios";
import { baseURL, encodeQuery } from "@/utils/helpers";
import { toggleFlashMessage } from "@/composables/useToast";

const isLoading = ref(false);
export const hasModal = ref(false);
const loadingTask = ref<string | null>(null);

axios.defaults.baseURL = baseURL;

const token = localStorage.getItem("token");
const resolveRequest = () => {
  isLoading.value = false;
  loadingTask.value = null;
  if (hasModal.value) {
    const el = document.querySelector("#alert-container");
    if (el) {
      el.remove();
    }
  }
};
export const setHttpBackgroundTask = (task: string | null) => {
  loadingTask.value = task;
};
axios.interceptors.request.use(
  (config) => {
    const branch = localStorage.getItem("__branch");
    if (branch) {
      config.url = encodeQuery(config.url as string, {
        current_branch: JSON.parse(branch).id,
      });
    }
    isLoading.value = true;
    return config;
  },
  (error) => {
    resolveRequest();
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    resolveRequest();
    return response;
  },
  (error) => {
    resolveRequest();
    if (error.response.status == 401 && location.pathname != "/") {
      localStorage.removeItem("token");
      location.replace("/");
    }
    if (error.response.status == 500) {
      toggleFlashMessage({
        type: "error",
        text: "Error. Contact system administrator",
      });
    }
    return Promise.reject(error);
  }
);

if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
const http = axios;

export function useHttp() {
  return {
    http,
    isLoading,
    loadingTask,
    resolveRequest,
    setHttpBackgroundTask,
    hasModal,
  };
}
