import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "TameSales" */ "../views/LoginView.vue"
      ),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/dashboard",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "TameSales" */ "../views/DashboardView.vue"
      ),
    meta: {
      title: "Dashboard",
      requireAuth: true,
    },
  },
  {
    path: "/pos/sales/:reference?",
    name: "PosSale",
    component: () =>
      import(
        /* webpackChunkName: "TameSales" */ "../views/SaleView.vue"
      ),
    meta: {
      title: "POS | Tame Sales",
      requireAuth: true,
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: () =>
      import(
        /* webpackChunkName: "TameSales" */ "../views/SalesReportView.vue"
      ),
    meta: {
      title: "Reports",
      requireAuth: true,
    },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const token = localStorage.getItem("token");
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (token) {
      next();
      return;
    }
    location.replace("/");
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (typeof to.meta.title === "string") {
    document.title = to.meta.title;
  }
});

export default router;
