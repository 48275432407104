import { useLayout } from "@/composables/useLayout";
import { Printd } from "printd";

const { appSettings } = useLayout();

let POS_URL = localStorage.getItem("__pos_url");
const url = get("url");
const token = get("token");
if (url && url != "") {
  localStorage.setItem("__pos_url", url as string);
  //openSettingsModal();
  setTimeout(() => {
    localStorage.setItem("token", token as string);
    location.replace("/pos/sales");
  }, 1500);
  POS_URL = url as string;
}

export const generateFormData = (obj: any) => {
  const fd = new FormData();
  for (const key in obj) {
    fd.append(
      key,
      typeof obj[key] == "object" ? JSON.stringify(obj[key]) : obj[key]
    );
  }
  return fd;
};

export const baseURL = POS_URL
  ? POS_URL
  : process.env.NODE_ENV == "production"
  ? "https://tameaps.com/"
  : "http://127.0.0.1:8000/api/inventory/";

export const serverUrl =
  process.env.NODE_ENV == "production"
    ? "https://tameaps.com/"
    : "http://127.0.0.1:8000/";

export const formatDate = (date: string) => {
  const d = new Date(date);
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  return `${mo} ${da}, ${ye}`;
};

export const isStandalone = () => {
  const webappsProtocols = ["file:", "cordova:", "capacitor:"];
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    (window.location &&
      window.location.protocol &&
      webappsProtocols.indexOf(window.location.protocol) !== -1)
  );
};

export const formatNumber = (num: number | string) => {
  if (num.toString().indexOf(".") > -1) num = Number(num).toFixed(3);
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatMoney = (num: number | string) => {
  return `${appSettings.currency} ${formatNumber(num)}`;
};

export const encodeQuery = (url: string, data: any) => {
  let query = "";
  for (const d in data) {
    if (data[d] && url.indexOf(`?${d}`) < 0 && url.indexOf(`&${d}`) < 0)
      query += encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
  }
  return url.indexOf("?") > -1
    ? `${url}&${query.slice(0, -1)}`
    : `${url}?${query.slice(0, -1)}`;
};

export const padNumber = (num: number, targetedLength = 5) => {
  const strNumber = num.toString();
  if (strNumber.length < targetedLength) {
    const padding = new Array(targetedLength - strNumber.length + 1).join("0");
    return padding + strNumber;
  }
};
export const dateDiffInDays = (a: Date, b: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function get(name: string) {
  let url = window.location.search;
  let num = url.search(name);
  const namel = name.length;
  const frontlength = namel + num + 1; //length of everything before the value
  const front = url.substring(0, frontlength);
  url = url.replace(front, "");
  num = url.search("&");
  if (num >= 0) return url.substr(0, num);
  if (num < 0) return url;
}

const styles = [
  `${location.origin}/css/style.css`,
  `${location.origin}/css/custom.css`,
];

export const printWithoutHeader = (elementId: string) => {
  const d = new Printd();
  const elt = document.querySelector(elementId) as any;
  d.print(elt, [...styles]);
};

export const handlePrint = (elementId: string, css = [], title?: string) => {
  const pageTitle = title ? title : document.title;
  const d = new Printd();
  const header = `<table class="table table-borderless mb-3">
  <tr>
    <td style="text-align: left; width: 60% !important">
      <div style="padding-left: 10px">
        <img src="/img/logo.png" class="img-fluid" style="height: 60px" />
      </div>
    </td>
    <td style="text-align: left; width: 40% !important">
      <div class="border-left">
        <h4>${appSettings.site_name}</h4>
        <h6>TIN Number: ${appSettings.app_tin}</h6>
        <h6>Address: ${appSettings.site_address}</h6>
        <h6>Tel: ${appSettings.app_phone}</h6>
        <h6>Email: ${appSettings.app_email}</h6>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" style="text-align: center">
      <h2 class="mb-0">${pageTitle}</h2>
    </td>
  </tr>
  <tr>
    <td colspan="2" style="text-align: center;">
      <div style="border-bottom: 2px solid #444;width: 100%; margin: auto;text-align: center;"></div>
    </td>
  </tr>
</table>`;
  const printEl = document.querySelector(elementId);
  const div = document.createElement("div");
  div.innerHTML = header.trim();
  if (printEl && printEl.parentElement) {
    printEl.parentElement.prepend(div);
    d.print(printEl.parentElement, [...styles, ...css]);
    setTimeout(() => {
      div.remove();
    }, 250);
  }
};

export const handleExcelExport = (excelFilename?: string, tableId?: string) => {
  if (!tableId) {
    tableId = "table.table";
  } else {
    tableId = "#" + tableId;
  }
  const container = document.querySelector(tableId) as any;
  const head = container.querySelector("thead");
  const headers = [...head.querySelectorAll("th:not(.action):not(.check)")].map(
    (item) => item.textContent
  );
  const dataset: any = [];
  [...container.querySelectorAll("tbody tr")].forEach((row) => {
    const arr = [...row.querySelectorAll("td:not(.action):not(.check)")].map(
      (item) => item.textContent
    );
    dataset.push(arr);
  });
  /**
   * Creating a temporary form
   */
  const dataForm = document.createElement("form");
  dataForm.target = "_blank";
  dataForm.method = "POST";
  dataForm.action = "https://tameaps.com/export.php";
  const records = document.createElement("input");
  const file_name = document.createElement("input");
  const headings = document.createElement("input");
  records.type = "hidden";
  file_name.type = "hidden";
  headings.type = "hidden";
  records.name = "dataset";
  records.value = JSON.stringify(dataset);
  file_name.name = "filename";
  file_name.value = excelFilename || document.title.replaceAll(" ", "-");
  headings.name = "columns";
  headings.value = JSON.stringify(headers);
  dataForm.appendChild(records);
  dataForm.appendChild(file_name);
  dataForm.appendChild(headings);
  dataForm.style.display = "none";
  document.body.appendChild(dataForm);
  dataForm.submit();
};
